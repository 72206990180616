import {
  isAPIError,
  useAppVariant,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useCallback } from "react";

export const useHandleApiError = () => {
  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const { t } = useTranslations();

  const handleApiError = useCallback(
    (error: unknown) => {
      const errorMessage = isAPIError(error)
        ? error.data.errors
        : t({
            id: "feedback.something-went-wrong",
            defaultMessage: "Something went wrong",
          });

      toast(errorMessage, {
        variant: "error",
      });
    },
    [toast, t]
  );

  return { handleApiError };
};
