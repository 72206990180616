import React, { useEffect, useRef, useState } from "react";
import { TaskActivity, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { MultiSectionLayout } from "@jugl-web/ui-components";
import { TaskCommentsInput } from "../TaskCommentsInput";
import { TaskSubTasksSection } from "../TaskSubTasksSection";
import { TaskAttachmentsSection } from "../TaskAttachmentsSection";
import { TaskFeedbackSection } from "../TaskFeedbackSection";
import { TaskCommentsSection } from "../TaskCommentsSection";
import { TaskDetailsSection } from "../TaskDetailsSection";
import { WebTaskPageHeader } from "../WebTaskPageHeader";

export const WebGuestTaskContent: React.FC<{
  taskData: GuestTaskDataModel;
}> = ({ taskData }) => {
  const { task } = taskData;
  const isTaskCompleted = task?.status === TaskDefaultStatus.completed;
  const $sideBarRef = useRef<HTMLDivElement>(null);
  const [editedComment, setEditedComment] = useState<TaskActivity>();

  useEffect(() => {
    const $commentsWrapper = $sideBarRef.current?.children?.[0]?.children?.[0];
    if ($commentsWrapper) {
      $commentsWrapper.scrollTo(0, $commentsWrapper.scrollHeight);
    }
  }, [taskData.activity]);

  return (
    <MultiSectionLayout
      className="h-screen bg-[#ECEFF0]"
      header={<WebTaskPageHeader />}
    >
      <div className="mx-auto h-full max-w-[1200px] px-4 pt-6">
        <div className="grid h-full grid-cols-3 gap-1">
          <div className="col-span-2 flex min-h-full flex-col gap-1 overflow-y-auto">
            <div className="rounded-tl-xl bg-white p-8 pt-2">
              <TaskDetailsSection taskData={taskData} />
            </div>

            {isTaskCompleted && <TaskFeedbackSection taskData={taskData} />}

            <div className="bg-white p-8 pt-6">
              <TaskSubTasksSection task={task} />
            </div>

            <div className="flex-1 bg-white p-8 pt-6">
              <TaskAttachmentsSection task={task} />
            </div>
          </div>
          <div className="overflow-hidden rounded-tr-lg bg-white">
            <MultiSectionLayout
              ref={$sideBarRef}
              footer={
                !isTaskCompleted && (
                  <TaskCommentsInput
                    entityId={task.entity_id}
                    taskId={task.id}
                    editedComment={editedComment}
                    cancelEditComment={() => setEditedComment(undefined)}
                  />
                )
              }
            >
              <TaskCommentsSection
                taskData={taskData}
                handleEditComment={setEditedComment}
              />
            </MultiSectionLayout>
          </div>
        </div>
      </div>
    </MultiSectionLayout>
  );
};
