import { FC } from "react";
import {
  Button,
  LoadingSpinner,
  PhoneInput,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as PadlockIcon } from "./assets/padlock.svg";
import { OtpVerification } from "../OtpVerification";
import { AuthContentProps } from "../../types/AuthContentProps";
import { PageWrapper } from "../../../../../common/components/PageWrapper";
import { BrandingLabel } from "../../../../../common/components/BrandingLabel";

export const MobileAuthContent: FC<AuthContentProps> = ({
  loginId,
  setLoginId,
  mobile,
  email,
  setMobile,
  setEmail,
  otp,
  setOtp,
  handleVerifyOtp,
  isVerifyOtpLoading,
  handleRequestOtp,
  isRequestOtpLoading,
  loginMethod,
  setLoginMethod,
  loginValue,
  isOtpValid,
  isLoginFormValid,
}) => {
  const { t } = useTranslations();
  if (loginId) {
    return (
      <PageWrapper>
        <div className="mx-auto">
          <OtpVerification
            value={otp}
            onChange={setOtp}
            onBack={() => {
              setLoginId(null);
              setOtp("");
            }}
            onReSend={handleRequestOtp}
            loginMethod={loginMethod}
            loginValue={loginValue}
          />
        </div>

        <div className="m-10 flex flex-col items-center gap-10">
          <Button
            className="h-10"
            fullWidth
            isDisabled={isVerifyOtpLoading || !isOtpValid}
            onClick={() => handleVerifyOtp()}
          >
            {isVerifyOtpLoading ? (
              <LoadingSpinner size="sm" />
            ) : (
              <span>
                {t({
                  id: "common.continue",
                  defaultMessage: "Continue",
                })}
              </span>
            )}
          </Button>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      footer={
        <div className="m-10 flex flex-col items-center gap-2">
          <Button
            className="h-10"
            fullWidth
            isDisabled={isRequestOtpLoading || !isLoginFormValid}
            onClick={handleRequestOtp}
          >
            {isRequestOtpLoading ? (
              <LoadingSpinner size="sm" />
            ) : (
              <span>
                {t({
                  id: "common.continue",
                  defaultMessage: "Continue",
                })}
              </span>
            )}
          </Button>
          <Button
            onClick={() =>
              setLoginMethod(loginMethod === "email" ? "mobile" : "email")
            }
            variant="text"
          >
            {loginMethod === "email"
              ? t({
                  id: "auth-guard-component.login-with-phone",
                  defaultMessage: "Login with phone",
                })
              : t({
                  id: "auth-guard-component.login-with-email",
                  defaultMessage: "Login with email",
                })}
          </Button>
          <div className="mt-2">
            <BrandingLabel />
          </div>
        </div>
      }
    >
      <div className="mx-4 flex flex-col pt-10">
        <div className="mb-12 flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <PadlockIcon />
            <span className="text-dark font-secondary text-xl font-semibold">
              {t({
                id: "auth-guard-component.login",
                defaultMessage: "Login",
              })}
            </span>
          </div>
          <span className="text-grey-800 text-sm leading-[21px]">
            {loginMethod === "email"
              ? t({
                  id: "auth-guard-component.to-login-enter-email-to-send-otp",
                  defaultMessage:
                    "To login and see Task, please enter your email to which we'll send you an OTP code",
                })
              : t({
                  id: "auth-guard-component.to-login-enter-phone-number-to-send-otp",
                  defaultMessage:
                    "To login and see Task, please enter your phone number to which we'll send you an OTP code",
                })}
          </span>
        </div>

        {loginMethod === "email" ? (
          <TextField
            placeholder={t({
              id: "auth-guard-component.email",
              defaultMessage: "Email",
            })}
            isFullWidth
            inputClassName="bg-grey-100 rounded-lg py-3 px-[18px] border-none"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        ) : (
          <div className="border-dark-100 w-full rounded-md border border-solid py-1">
            <PhoneInput
              value={mobile}
              placeholder={t({
                id: "auth-guard-component.mobile-number",
                defaultMessage: "Mobile Number",
              })}
              onChange={setMobile}
            />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
