import React, { useCallback, useEffect, useRef } from "react";
import {
  CommentsInput,
  CommentsInputHandle,
  CommentsInputParams,
} from "@jugl-web/ui-components/cross-platform/CommentsInput";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskActivity } from "@jugl-web/rest-api/tasks";

export const TaskCommentsInput: React.FC<{
  entityId: string;
  taskId: string;
  editedComment?: TaskActivity;
  cancelEditComment: () => void;
}> = ({ entityId, taskId, editedComment, cancelEditComment }) => {
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const { variant } = useAppVariant();
  const { toast } = useToast({
    variant,
  });
  const $inputRef = useRef<CommentsInputHandle>(null);

  const [sendGuestTaskComment, { isLoading: isSendGuestTaskCommentLoading }] =
    tasksApi.useSendGuestTaskCommentMutation();

  const [
    updateGuestTaskComment,
    { isLoading: isUpdateGuestTaskCommentLoading },
  ] = tasksApi.useUpdateGuestTaskCommentMutation();

  const handleCommentSubmit: CommentsInputParams["onSubmit"] = useCallback(
    async ({ body }) => {
      const response = await sendGuestTaskComment({
        entityId,
        taskId,
        data: {
          content: body,
        },
      });
      if ("error" in response) {
        toast(
          t({
            id: "guest-task-page.message-sending-failed-try-again",
            defaultMessage: "Message sending failed. Please try again",
          }),
          { variant: "error" }
        );
        return;
      }
      if ("data" in response) {
        $inputRef.current?.clear();
        setTimeout(() => {
          $inputRef.current?.focus();
        }, 100);
      }
    },
    [toast, t, entityId, sendGuestTaskComment, taskId]
  );

  const handleCommentEdit: CommentsInputParams["onSubmit"] = useCallback(
    async ({ body }) => {
      if (!editedComment) return;
      const response = await updateGuestTaskComment({
        entityId,
        taskId,
        data: {
          content: body,
        },
        commentId: editedComment.id,
      });
      if (response && "error" in response) {
        toast(
          t({
            id: "guest-task-page.message-edit-failed-try-again",
            defaultMessage: "Message edit failed. Please try again",
          }),
          { variant: "error" }
        );
        return;
      }
      if ("data" in response) {
        cancelEditComment();
        $inputRef.current?.clear();
        setTimeout(() => {
          $inputRef.current?.focus();
        }, 100);
      }
    },
    [
      toast,
      t,
      editedComment,
      entityId,
      taskId,
      updateGuestTaskComment,
      cancelEditComment,
    ]
  );

  useEffect(() => {
    if (
      !editedComment ||
      editedComment.action_details.action !== "guest_comment"
    ) {
      return;
    }
    $inputRef.current?.setValue({
      body: editedComment.action_details.changes.content,
    });
    $inputRef.current?.focus();
  }, [editedComment]);

  return (
    <div className="flex w-full items-center gap-2 bg-white py-3 px-4 drop-shadow-[0_-4px_8px_rgba(0,0,0,0.08)]">
      {editedComment && (
        <span className="text-primary">
          {t({
            id: "common.edit",
            defaultMessage: "Edit",
          })}
          :
        </span>
      )}
      <CommentsInput
        variant={variant}
        ref={$inputRef}
        isLoading={
          isSendGuestTaskCommentLoading || isUpdateGuestTaskCommentLoading
        }
        onSubmit={editedComment ? handleCommentEdit : handleCommentSubmit}
        mentions={[]}
        placeholder={t({
          id: "guest-task-page.add-your-comments",
          defaultMessage: "Add your comments",
        })}
      />
    </div>
  );
};
