import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import { SubTask } from "./components/SubTask";
import { PageSection } from "../PageSection";
import { ReactComponent as SubTasksIcon } from "./assets/sub-tasks.svg";

export const TaskSubTasksSection: React.FC<{
  task: DetailedTask;
}> = ({ task }) => {
  const { t } = useTranslations();

  return (
    <PageSection
      title={t({
        id: "guest-task-page.sub-tasks",
        defaultMessage: "Sub-Tasks",
      })}
      icon={<SubTasksIcon />}
    >
      {!task.checklist.length ? (
        <span className="text-dark-500 text-xs">
          {t({
            id: "guest-task-page.no-sub-tasks-yet",
            defaultMessage: "No Sub-Tasks yet",
          })}
        </span>
      ) : (
        <div className="flex flex-col">
          {task.checklist.map((item, idx, array) => (
            <SubTask
              key={item.id}
              name={item.name}
              isCompleted={item.is_completed}
              completedAt={item.completed_at}
              isLast={idx === array.length - 1}
            />
          ))}
        </div>
      )}
    </PageSection>
  );
};
