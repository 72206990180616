import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RestApiProvider } from "@jugl-web/rest-api";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { EnhancedIntlProvider } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { SnackbarProvider } from "notistack";
import { FilePreviewProvider } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { AppVariantProvider } from "@jugl-web/utils/providers/AppVariantProvider";
import { App } from "./app/app";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import { AuthProvider } from "./modules/auth/providers/AuthProvider";
import { emptySplitApi, connectedApis, apiUrl } from "./modules/api/api";
import { IntercomProvider } from "./components/IntercomProvider";

const store = configureStore({
  reducer: {
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptySplitApi.middleware),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <RestApiProvider apis={connectedApis} apiBaseUrl={apiUrl}>
          <AppVariantProvider auto>
            <SnackbarProvider maxSnack={3}>
              <AuthProvider>
                <EnhancedIntlProvider>
                  <FilePreviewProvider>
                    <IntercomProvider>
                      <App />
                    </IntercomProvider>
                  </FilePreviewProvider>
                </EnhancedIntlProvider>
              </AuthProvider>
            </SnackbarProvider>
          </AppVariantProvider>
        </RestApiProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
