import { Route, Routes } from "react-router-dom";
import { GuestOrderFormPage } from "@web-guest-src/modules/orders/pages/GuestOrderFormPage";
import { AuthGuard } from "../modules/auth/components/AuthGuard";
import { GuestTaskPage } from "../modules/tasks/pages/GuestTaskPage";

export function App() {
  return (
    <Routes>
      <Route
        path="/guest/task/:taskShortId"
        element={
          <AuthGuard>
            <GuestTaskPage />
          </AuthGuard>
        }
      />
      <Route path="/order/:entityId/:formId" element={<GuestOrderFormPage />} />
      <Route path="/" element={<div>Not found</div>} />
    </Routes>
  );
}

export default App;
