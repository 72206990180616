import React, { useCallback, useMemo, useState } from "react";
import { Alert } from "@jugl-web/ui-components";
import Lottie from "react-lottie";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  DetailedTask,
  TaskActivity,
  TaskDefaultStatus,
} from "@jugl-web/rest-api/tasks";
import { FeedbackForm, FeedbackHashtag } from "./components/FeedbackForm";
import thanksAnimation from "./assets/thanks-animation.json";
import { PageSection } from "../PageSection";
import { ReactComponent as FeedbackIcon } from "./assets/feedback.svg";
import { CommentItem } from "../CommentItem/CommentItem";

export const TaskFeedbackSection: React.FC<{
  taskData: {
    task: DetailedTask;
    activity: TaskActivity[];
  };
}> = ({ taskData: { activity, task } }) => {
  const [showAlert, setShowAlert] = useState(false);

  const { isWeb, variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const { tasksApi } = useRestApiProvider();
  const { t } = useTranslations();

  const [sendGuestFeedback, { isLoading: isSendGuestFeedbackLoading }] =
    tasksApi.useSendGuestFeedbackMutation();

  const guestFeedback = useMemo(
    () =>
      activity.find(
        (activityItem) =>
          activityItem.action_details.action === "guest_feedback"
      ),
    [activity]
  );

  const onFeedbackSubmit = useCallback(
    async (
      feedback: string,
      starsCount: number,
      hashtags: FeedbackHashtag[]
    ) => {
      const response = await sendGuestFeedback({
        taskId: task.id,
        entityId: task.entity_id,
        data: {
          feedback,
          hashtag: hashtags.map(
            (hashtag) => `#${hashtag.label.replace(/ /g, "_")}`
          ),
          rating: starsCount,
        },
      });
      if (response && "error" in response) {
        toast(
          t({
            id: "guest-task-page.feedback-submission-failed-try-again",
            defaultMessage: "Feedback submission failed. Please try again",
          })
        );
        return;
      }
      if (response && "data" in response) {
        setShowAlert(true);
      }
    },
    [sendGuestFeedback, task, toast, t]
  );

  const $content = (
    <PageSection
      title={t({
        id: "guest-task-page.feedback",
        defaultMessage: "Feedback",
      })}
      icon={<FeedbackIcon />}
    >
      {guestFeedback ? (
        <CommentItem
          activity={guestFeedback}
          entityId={task.entity_id}
          taskId={task.id}
          isTaskCompleted={task.status === TaskDefaultStatus.completed}
        />
      ) : (
        <FeedbackForm
          onSubmit={onFeedbackSubmit}
          isLoading={isSendGuestFeedbackLoading}
        />
      )}
    </PageSection>
  );

  return (
    <>
      {!isWeb && $content}
      {isWeb && !guestFeedback && (
        <div className="bg-white p-8 pt-6">{$content}</div>
      )}
      <Alert
        isOpen={showAlert}
        onRequestClose={() => setShowAlert(false)}
        img={
          <Lottie
            options={{ animationData: thanksAnimation }}
            height={160}
            width={240}
          />
        }
        title={t({
          id: "guest-task-page.we-appreciate-your-feedback",
          defaultMessage: "We appreciate your feedback!",
        })}
        content={t({
          id: "guest-task-page.see-you-next-time",
          defaultMessage: "See you next time!",
        })}
        buttons={[
          {
            text: t({
              id: "common.sure",
              defaultMessage: "Sure",
            }),
            color: "primary",
            role: "close",
          },
        ]}
      />
    </>
  );
};
