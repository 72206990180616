import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as JuglLogoIcon } from "./assets/jugl-logo.svg";

export const BrandingLabel: React.FC = () => {
  const { t } = useTranslations();

  return (
    <div className="flex items-center gap-1">
      <span className="font-secondary text-grey-background text-xs">
        {t({
          id: "common.powered-by",
          defaultMessage: "Powered by",
        })}
      </span>
      <JuglLogoIcon />
    </div>
  );
};
