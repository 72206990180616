import React from "react";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "@jugl-web/utils/consts";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as GradientIcon } from "./assets/gradient.svg";
import { ReactComponent as CirclesIcon } from "./assets/circles.svg";

export const PageWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslations();

  return (
    <div className="bg-primary-200 fixed top-0 left-0 right-0 bottom-0 flex overflow-y-auto">
      <div className="fixed h-full w-full">
        <GradientIcon className="absolute top-0 right-0 translate-x-2/4 -translate-y-2/4 blur-[75px]" />
        <GradientIcon className="absolute bottom-0 left-0 -translate-x-2/4 translate-y-2/4 blur-[75px]" />
        <CirclesIcon className="absolute top-1/2 left-1/2 w-[85%] -translate-x-1/2 -translate-y-1/2" />
      </div>
      <div className="relative z-10 m-auto p-5">
        <div className="relative flex h-[700px] w-[520px] flex-col items-center rounded-3xl bg-white px-[60px] py-14 text-center">
          {children}
        </div>
        <div className="text-dark-700 mt-5 text-center text-sm leading-[160%]">
          {t(
            {
              id: "auth-guard-component.terms-and-conditions-and-privacy-policy",
              defaultMessage:
                "<termsAndConditions>Terms and Conditions</termsAndConditions> and <privacyPolicy>Privacy Policy</privacyPolicy>",
            },
            {
              termsAndConditions: (text: (string | JSX.Element)[]) => (
                <a
                  className="text-primary-800 underline"
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              privacyPolicy: (text: (string | JSX.Element)[]) => (
                <a
                  className="text-primary-800 underline"
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            }
          )}
        </div>
      </div>
    </div>
  );
};
