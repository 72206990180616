import { useRestApiProvider } from "@jugl-web/rest-api";
import { ExpectedTaskCustomDropdownFieldValue } from "@jugl-web/rest-api/tasks";
import {
  DataLoadingWrapper,
  MultiSectionLayout,
} from "@jugl-web/ui-components";
import { WebTaskPageHeader } from "@web-guest-src/modules/tasks/pages/GuestTaskPage/components/WebTaskPageHeader";
import { FC, useState } from "react";
import { useMatch } from "react-router-dom";
import { OrderFormSubmission } from "@jugl-web/domain-resources/orders";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { getDueDateBasedOnDays } from "@jugl-web/domain-resources/tasks/utils";
import Lottie from "react-lottie";
import linkExpiredAnimation from "./assets/error-animation.json";

export const GuestOrderFormPage: FC = () => {
  const match = useMatch("/order/:entityId/:formId");
  if (!match?.params.entityId || !match?.params.formId) {
    throw new Error("Invalid route");
  }
  const { ordersApi } = useRestApiProvider();
  const { entityId, formId } = match.params;
  const { isLoading, data, isError } = ordersApi.useGuestOrderFormQuery({
    entityId,
    formId,
  });
  const { isLoading: isInitLoading, data: initData } =
    ordersApi.useGuestInitQuery({ entityId });

  const [submitOrderForm, { isLoading: isSubmitting }] =
    ordersApi.useGuestSubmitOrderMutation();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const formFields = data?.fields
    .map((item: any) => {
      if (item.id === TASK_ORDER_EMAIL_ID) {
        return {
          id: item.id,
          name: "Email",
          type: "text",
          order: -1,
          isShownInCard: false,
          isRequired: true,
        };
      }
      const customField = initData?.custom_fields?.props?.value.find(
        (customFieldItem) => customFieldItem.id === item.id
      );
      if (!customField) {
        return null;
      }
      return {
        id: customField.id,
        name: customField.field,
        type: customField.field_type,
        order: item.order,
        isShownInCard: false,
        values:
          customField.values as unknown as ExpectedTaskCustomDropdownFieldValue[],
        isRequired: item.required,
      };
    })
    .filter((item: any) => !!item);

  return (
    <MultiSectionLayout
      header={<WebTaskPageHeader title={initData?.entity?.name} />}
      className="h-[100vh] bg-gray-50"
    >
      <DataLoadingWrapper
        isLoading={isLoading || isInitLoading}
        isError={isError}
        customErrorContentProps={{
          type: "custom",
          customTitle: "Form not found",
          customSubtitle: "This Form is missing or deleted",
          customImg: (
            <Lottie options={{ animationData: linkExpiredAnimation }} />
          ),
        }}
      >
        {data && (
          <div className="mx-auto px-4">
            <div className="mx-auto mt-[60px] flex max-w-[1080px] flex-col items-center rounded-xl bg-white p-6">
              <OrderFormSubmission
                isGuestApp
                onSubmit={async (formValues) => {
                  if (!entityId || !formValues) {
                    return;
                  }
                  const customFields: Record<string, string> = {};
                  Object.keys(formValues).forEach((key) => {
                    if (formValues[key] instanceof Date) {
                      customFields[key] = (
                        formValues[key] as Date
                      ).toISOString();
                      return;
                    }
                    customFields[key] = formValues[key] as string;
                  });
                  const resp = await submitOrderForm({
                    entityId,
                    formId,
                    data: {
                      name: data.name,
                      priority: data.priority || null,
                      due_at:
                        data.due_in && !Number.isNaN(data.due_in)
                          ? getDueDateBasedOnDays(data.due_in).toISOString()
                          : null,
                      fields: Object.keys(formValues).map((key) => ({
                        id: key,
                        value: formValues[key] as string,
                      })),
                      custom_fields: customFields,
                      // custom_fields: {},
                      can_assignee_edit: data.can_assignee_edit,
                      has_chklist_chk: data.has_chklist_chk,
                    },
                  });
                  if (resp && "data" in resp) {
                    setIsSubmitted(true);
                  }
                }}
                isSubmitted={isSubmitted}
                submittedMessage={data.msg}
                onReset={() => setIsSubmitted(false)}
                isLoading={isSubmitting}
                values={{
                  title: data.title || "",
                  description: data.form_desc || "",
                  image: data.banner_img,
                  fields: formFields || [],
                }}
              />
            </div>
          </div>
        )}
      </DataLoadingWrapper>
    </MultiSectionLayout>
  );
};
