import React, { useEffect, useRef, useState } from "react";
import { TaskActivity, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { BrandingLabel } from "@web-guest-src/modules/common/components/BrandingLabel";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { TaskCommentsInput } from "../TaskCommentsInput";
import { PageDivider } from "../PageDivider/PageDivider";
import { TaskSubTasksSection } from "../TaskSubTasksSection";
import { TaskAttachmentsSection } from "../TaskAttachmentsSection";
import { TaskFeedbackSection } from "../TaskFeedbackSection";
import { TaskCommentsSection } from "../TaskCommentsSection";
import { TaskDetailsSection } from "../TaskDetailsSection";

export const MobileGuestTaskContent: React.FC<{
  taskData: GuestTaskDataModel;
}> = ({ taskData }) => {
  const { task } = taskData;
  const isTaskCompleted = task?.status === TaskDefaultStatus.completed;
  const [editedComment, setEditedComment] = useState<TaskActivity>();

  const $pageWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    $pageWrapper.current?.scrollTo(0, $pageWrapper.current.scrollHeight);
  }, [taskData.activity]);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex-1" ref={$pageWrapper}>
        <TaskDetailsSection taskData={taskData} />

        <PageDivider />

        <TaskSubTasksSection task={task} />

        <PageDivider />

        <TaskAttachmentsSection task={task} />

        <PageDivider />

        <TaskCommentsSection
          taskData={taskData}
          handleEditComment={setEditedComment}
        />

        {isTaskCompleted && (
          <>
            <PageDivider />
            <TaskFeedbackSection taskData={taskData} />
          </>
        )}

        <PageDivider />

        <div className="mb-8 px-4">
          <BrandingLabel />
        </div>
      </div>

      {!isTaskCompleted && (
        <div className="sticky bottom-0 left-0 w-full">
          <TaskCommentsInput
            entityId={task.entity_id}
            taskId={task.id}
            editedComment={editedComment}
            cancelEditComment={() => setEditedComment(undefined)}
          />
        </div>
      )}
    </div>
  );
};
