import Intercom, { hide, show, shutdown } from "@intercom/messenger-js-sdk";
import { HookOutOfContextError } from "@jugl-web/utils";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IntercomContextValue {
  isVisible: boolean;
  toggle: () => void;
}

const IntercomContext = createContext<IntercomContextValue | null>(null);

interface IntercomProviderProps {
  children: ReactNode;
}

export const IntercomProvider: FC<IntercomProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggle = useCallback(() => {
    if (isVisible) {
      hide();
    } else {
      show();
    }
  }, [isVisible]);

  useEffect(() => {
    Intercom({
      app_id: "d8ychfjy",
      hide_default_launcher: true,
    });

    // Need to use the global instance because the `onShow` and `onHide` methods from SDK
    // make the `show` and `hide` SDK methods not to work
    window.Intercom?.("onShow", () => setIsVisible(true));
    window.Intercom?.("onHide", () => setIsVisible(false));

    return () => {
      shutdown();
    };
  }, []);

  useEffect(() => {
    // Same as above, need to use the global instance as it's not working with the SDK methods
    window.Intercom?.("update");
  }, []);

  const contextValue = useMemo<IntercomContextValue>(
    () => ({
      isVisible,
      toggle,
    }),
    [isVisible, toggle]
  );

  return (
    <IntercomContext.Provider value={contextValue}>
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercom = () => {
  const context = useContext(IntercomContext);

  if (!context) {
    throw new HookOutOfContextError("useIntercom", "IntercomContext");
  }

  return context;
};
