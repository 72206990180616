import { TaskActivity, TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import React, { useEffect, useMemo, useRef } from "react";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { GuestTaskDataModel } from "@jugl-web/rest-api/tasks/models/GuestTaskData";
import { PageSection } from "../PageSection";
import { ReactComponent as CommentsIcon } from "./assets/comments.svg";
import { CommentItem } from "../CommentItem/CommentItem";

export const TaskCommentsSection: React.FC<{
  taskData: GuestTaskDataModel;
  handleEditComment: (comment: TaskActivity) => void;
}> = ({ taskData, handleEditComment }) => {
  const { t } = useTranslations();
  const { isWeb } = useAppVariant();

  const activityItems = useMemo(
    () =>
      [
        ...(isWeb
          ? [...taskData.activity].reverse()
          : taskData.activity
              .filter(
                (item) => item.action_details?.action !== "guest_feedback"
              )
              .reverse()),
      ].filter((item) => item.action_details?.action !== "guest_opened"),
    [taskData.activity, isWeb]
  );

  const $comments = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isWeb) {
      const $commentsWrapper = $comments.current?.parentNode as HTMLDivElement;
      $commentsWrapper?.scrollTo(0, $commentsWrapper.scrollHeight);
    }
  }, [isWeb]);

  const $content = !activityItems?.length ? (
    <div ref={$comments} className="text-dark-500 mt-7 px-8 text-xs">
      {t({
        id: "guest-task-page.no-comments-yet",
        defaultMessage: "No Comments yet",
      })}
    </div>
  ) : (
    <div className={cx("flex flex-col", !isWeb && "gap-2")} ref={$comments}>
      {activityItems.map((activity) => (
        <CommentItem
          key={activity.id}
          activity={activity}
          entityId={taskData.entity.id}
          taskId={taskData.task.id}
          isTaskCompleted={taskData.task.status === TaskDefaultStatus.completed}
          handleEditComment={() => handleEditComment(activity)}
        />
      ))}
    </div>
  );

  return isWeb ? (
    <>
      <div className="sticky top-0 z-50 flex items-center gap-2 bg-white py-[14px] px-8">
        <CommentsIcon />
        <span className="text-dark font-secondary text-sm leading-3 tracking-[0.14px]">
          {t({
            id: "guest-task-page.comments",
            defaultMessage: "Comments",
          })}
        </span>
      </div>
      <div className="bg-grey-200 h-px" />
      {$content}
    </>
  ) : (
    <PageSection
      title={t({
        id: "guest-task-page.comments",
        defaultMessage: "Comments",
      })}
      icon={<CommentsIcon />}
    >
      {$content}
    </PageSection>
  );
};
