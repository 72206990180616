import { FC } from "react";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { PageWrapper } from "@web-guest-src/modules/common/components/PageWrapper";
import Lottie from "react-lottie";
import { EmptyListContent, MultiSectionLayout } from "@jugl-web/ui-components";
import { BrandingLabel } from "@web-guest-src/modules/common/components/BrandingLabel";
import { useAuthProvider } from "@web-guest-src/modules/auth/providers/AuthProvider";
import linkExpiredAnimation from "./assets/link-expired-animation.json";
import { WebTaskPageHeader } from "../WebTaskPageHeader";

export const TaskExpiredContent: FC = () => {
  const { isWeb } = useAppVariant();
  const { t } = useTranslations();
  const { logout } = useAuthProvider();

  return (
    <PageWrapper>
      <MultiSectionLayout
        className="bg-gray-100"
        header={isWeb && <WebTaskPageHeader />}
        footer={
          !isWeb && (
            <div className="mx-auto w-max p-6">
              <BrandingLabel />
            </div>
          )
        }
      >
        <EmptyListContent
          type="custom"
          customImg={
            <Lottie
              options={{ animationData: linkExpiredAnimation }}
              height={180}
              width={300}
            />
          }
          customTitle={t({
            id: "guest-task-page.link-is-expired",
            defaultMessage: "Link is expired",
          })}
          customSubtitle={t({
            id: "guest-task-page.access-to-task-has-been-removed",
            defaultMessage:
              "Your access to this Task has been removed. Reach out to Workspace Owner to restore access",
          })}
          customButton={{
            text: t({
              id: "guest-task-page.try-different-login-id",
              defaultMessage: "Try different login ID",
            }),
            size: "small",
            onClick: () => {
              logout();
            },
          }}
        />
      </MultiSectionLayout>
    </PageWrapper>
  );
};
