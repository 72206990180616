import {
  addDriveApi,
  addTasksApi,
  baseQuery,
  getAxiosInstance,
} from "@jugl-web/rest-api";
import { addAuthApi } from "@jugl-web/rest-api/auth/authApi";
import { addOrdersApi } from "@jugl-web/rest-api/orders/ordersApi";
import { createApi } from "@reduxjs/toolkit/query/react";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { tokenStorage } from "../auth/utils/tokenStorage";

export const { apiUrl } = environment;

const api = getAxiosInstance(
  () => tokenStorage.getToken() || undefined,
  apiUrl
);

export const sessionExpired$ = new Subject<boolean>();

api.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) {
    sessionExpired$.next(true);
  }
  throw error;
});

export const emptySplitApi = createApi({
  reducerPath: "api",
  baseQuery: baseQuery(api),
  endpoints: () => ({}),
});

const authApi = addAuthApi(emptySplitApi);
const tasksApi = addTasksApi(emptySplitApi);
const driveApi = addDriveApi(emptySplitApi);
const ordersApi = addOrdersApi(emptySplitApi);

export const connectedApis = {
  authApi,
  tasksApi,
  driveApi,
  ordersApi,
};
