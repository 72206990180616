import { MultiSectionLayout } from "@jugl-web/ui-components";
import { FC, PropsWithChildren, ReactNode } from "react";

export type PageWrapperProps = PropsWithChildren<{
  header?: ReactNode;
  footer?: ReactNode;
}>;

export const PageWrapper: FC<PageWrapperProps> = ({
  children,
  footer,
  header,
}) => (
  <MultiSectionLayout footer={footer} header={header} className="h-screen">
    {children}
  </MultiSectionLayout>
);
