import { HookOutOfContextError, useToast } from "@jugl-web/utils";
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { sessionExpired$ } from "../../../api/api";
import { tokenStorage } from "../../utils/tokenStorage";

interface AuthContextValue {
  isAuthenticated: boolean;
  token: string | null;
  meId: string | null;
  authenticate: (token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(() =>
    tokenStorage.getToken()
  );
  const { toast } = useToast({
    variant: "web",
  });
  const authenticate = useCallback((newToken: string) => {
    tokenStorage.setToken(newToken);
    setToken(newToken);
  }, []);

  const logout = useCallback(() => {
    tokenStorage.clearToken();
    setToken(null);
  }, []);

  const meId = useMemo(() => {
    if (token) {
      return (
        JSON.parse(
          decodeURIComponent(window.escape(window.atob(token.split(".")[1])))
        )?.jid || null
      );
    }
    return null;
  }, [token]);

  const value: AuthContextValue = useMemo(
    () => ({ isAuthenticated: !!token, meId, token, authenticate, logout }),
    [token, meId, authenticate, logout]
  );

  useEffect(() => {
    const subscription = sessionExpired$.subscribe(() => {
      setToken(null);
      toast("Session expired. Please login again.", { variant: "error" });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [toast]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthProvider = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new HookOutOfContextError("useAuthProvider", "AuthContext");
  }

  return context;
};
