import React from "react";
import {
  InteractiveContainer,
  OTPVerificationForm,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as PadlockIcon } from "./assets/padlock.svg";
import { LoginMethod } from "../../types/LoginMethod";

export const OtpVerification: React.FC<{
  value: string;
  onChange: (otp: string) => void;
  loginMethod: LoginMethod;
  loginValue: string;
  onBack: () => void;
  onReSend: () => void;
}> = ({ value, onChange, loginMethod, loginValue, onBack, onReSend }) => {
  const { t } = useTranslations();

  return (
    <div className="mx-4 mt-8 flex flex-col gap-10">
      <InteractiveContainer
        className="bg-grey-100 flex h-8 w-8 items-center justify-center rounded-lg"
        onClick={onBack}
      >
        <ArrowIcon />
      </InteractiveContainer>
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <PadlockIcon />
          <span className="text-dark font-secondary text-xl font-semibold">
            {t({
              id: "auth-guard-component.enter-otp",
              defaultMessage: "Enter OTP",
            })}
          </span>
        </div>
        <span className="text-grey-800 text-sm leading-[21px]">
          {loginMethod === "email" ? (
            <span>
              {t(
                {
                  id: "auth-guard-component.enter-otp-sent-to-email",
                  defaultMessage:
                    "Please enter OTP Code sent to Your email {email}",
                },
                {
                  email: (
                    <span className="text-messages-chatBubbleSender font-semibold">
                      {loginValue}
                    </span>
                  ),
                }
              )}
            </span>
          ) : (
            <span>
              {t(
                {
                  id: "auth-guard-component.enter-otp-sent-to-phone-number",
                  defaultMessage:
                    "Please enter OTP Code sent to Your phone number {phoneNumber}",
                },
                {
                  phoneNumber: (
                    <span className="text-messages-chatBubbleSender font-semibold">
                      {loginValue}
                    </span>
                  ),
                }
              )}
            </span>
          )}
        </span>
      </div>
      <OTPVerificationForm
        onResend={onReSend}
        otp={value}
        onChange={onChange}
        className="p-0"
      />
    </div>
  );
};
