import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  PhoneInputValue,
  phoneStringToInputValue,
} from "@jugl-web/ui-components/cross-platform";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useAppVariant } from "@jugl-web/utils";
import { useSearchParams } from "react-router-dom";
import { useAuthProvider } from "../../providers/AuthProvider";
import { LoginMethod } from "./types/LoginMethod";
import { MobileAuthContent } from "./components/MobileAuthContent";
import { WebAuthContent } from "./components/WebAuthContent/WebAuthContent";
import { useHandleApiError } from "../../../api/useHandleApiError";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^\+\d+$/;

export const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { authApi } = useRestApiProvider();
  const [guestRegister, { isLoading: isGuestRegisterLoading }] =
    authApi.useGuestRegisterMutation();
  const [guestVerifyOtp, { isLoading: isVerifyOtpLoading }] =
    authApi.useGuestRegisterVerifyMutation();
  const [searchParams] = useSearchParams();
  const queryParamsLoginId = searchParams.get("login_id")?.replace(/\s+/g, "+");

  const { authenticate, isAuthenticated } = useAuthProvider();
  const { handleApiError } = useHandleApiError();

  const [loginMethod, setLoginMethod] = useState<LoginMethod>("mobile");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState<PhoneInputValue>();
  const [loginId, setLoginId] = useState<string | null>(null);

  useEffect(() => {
    if (!queryParamsLoginId) return;
    if (emailRegex.test(queryParamsLoginId)) {
      setLoginMethod("email");
      setEmail(queryParamsLoginId);
    }
    if (phoneRegex.test(queryParamsLoginId)) {
      setLoginMethod("mobile");
      setMobile(phoneStringToInputValue(queryParamsLoginId));
    }
  }, [queryParamsLoginId]);

  const isLoginFormValid = useMemo(() => {
    if (loginMethod === "email") {
      return emailRegex.test(email);
    }
    return !!mobile?.isValid;
  }, [email, loginMethod, mobile?.isValid]);

  const resetInternalState = () => {
    setEmail("");
    setMobile(undefined);
    setLoginId(null);
  };

  const handleRequestOtp = async () => {
    const value =
      loginMethod === "email" ? email : `${mobile?.code}${mobile?.phone}`;
    const response = await guestRegister({
      data: loginMethod === "email" ? { email: value } : { mobile: value },
    });
    if ("error" in response) {
      handleApiError(response.error);
      return;
    }
    if ("data" in response) {
      setLoginId(response.data.id);
    }
  };

  const handleVerifyOtp = async (argsOtp?: string) => {
    if (!loginId) {
      return;
    }
    const response = await guestVerifyOtp({
      data: {
        id: loginId,
        otp: otp || argsOtp || "",
      },
    });
    if ("error" in response) {
      handleApiError(response.error);
      return;
    }
    if ("data" in response) {
      authenticate(response.data.token);
      resetInternalState();
    }
  };

  const [otp, setOtp] = useState("");
  const isOtpValid = otp.length === 6;

  const { variant: appVariant } = useAppVariant();
  const loginValue =
    loginMethod === "email" ? email : `${mobile?.code}${mobile?.phone}`;

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (appVariant === "mobile") {
    return (
      <MobileAuthContent
        loginId={loginId}
        setLoginId={setLoginId}
        mobile={mobile}
        setMobile={setMobile}
        email={email}
        setEmail={setEmail}
        isLoginFormValid={isLoginFormValid}
        otp={otp}
        setOtp={setOtp}
        isOtpValid={isOtpValid}
        handleRequestOtp={handleRequestOtp}
        isRequestOtpLoading={isGuestRegisterLoading}
        handleVerifyOtp={handleVerifyOtp}
        isVerifyOtpLoading={isVerifyOtpLoading}
        loginValue={loginValue}
        loginMethod={loginMethod}
        setLoginMethod={setLoginMethod}
      />
    );
  }
  return (
    <WebAuthContent
      loginId={loginId}
      setLoginId={setLoginId}
      mobile={mobile}
      setMobile={setMobile}
      email={email}
      setEmail={setEmail}
      isLoginFormValid={isLoginFormValid}
      otp={otp}
      setOtp={setOtp}
      isOtpValid={isOtpValid}
      handleRequestOtp={handleRequestOtp}
      isRequestOtpLoading={isGuestRegisterLoading}
      handleVerifyOtp={handleVerifyOtp}
      isVerifyOtpLoading={isVerifyOtpLoading}
      loginValue={loginValue}
      loginMethod={loginMethod}
      setLoginMethod={setLoginMethod}
    />
  );
};
