import { cx, isoToLocalDate, useTranslations } from "@jugl-web/utils";
import React from "react";
import format from "date-fns/format";
import { Linkify } from "@jugl-web/utils/utils/Linkify";
import { Mentionify } from "@jugl-web/utils/utils/Mentionify";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

export const SubTask: React.FC<{
  isCompleted: boolean;
  completedAt: string | null;
  name: string;
  isLast: boolean;
}> = ({ isCompleted, isLast, name, completedAt }) => {
  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  return (
    <div className="flex gap-2.5">
      <div className="flex flex-col items-center">
        <div
          className={cx(
            "flex h-6 w-6 shrink-0 items-center justify-center rounded-full",
            isCompleted ? "bg-primary text-white" : "bg-grey-200 text-grey-800"
          )}
        >
          <CheckIcon />
        </div>
        {!isLast && <div className="bg-dark-200 h-full w-px" />}
      </div>
      <div className={cx("flex min-w-0 flex-col", !isLast && "mb-10")}>
        <Linkify>
          <Mentionify>
            <span className="whitespace-pre-wrap break-words text-sm font-medium leading-[21px] text-black">
              {name}
            </span>
          </Mentionify>
        </Linkify>
        {isCompleted && completedAt && (
          <span className="text-dark-500 text-xs leading-[17px] tracking-[0.12px]">
            {t(
              {
                id: "guest-task-page.sub-task-completion-date",
                defaultMessage: "Completed on {date}",
              },
              {
                date: format(isoToLocalDate(completedAt), "MMMM d, hh:mm a", {
                  locale: dateLocale,
                }),
              }
            )}
          </span>
        )}
      </div>
    </div>
  );
};
