import { InteractiveContainer } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";
import React from "react";

export const MethodTab: React.FC<{
  title: string;
  isChecked: boolean;
  onClick: () => void;
}> = ({ title, isChecked, onClick }) => (
  <InteractiveContainer
    className="flex w-[148px] flex-col gap-3"
    onClick={onClick}
  >
    <span
      className={cx(
        "leading-[26px] transition-colors",
        isChecked ? "text-primary-800 font-semibold" : "text-[#69757C]"
      )}
    >
      {title}
    </span>
    <div
      className={cx(
        "h-1 w-full rounded-lg transition-colors",
        isChecked ? "bg-primary" : "bg-grey-300"
      )}
    />
  </InteractiveContainer>
);
