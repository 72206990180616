import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  InteractiveContainer,
  LoadingSpinner,
  PlainButton,
} from "@jugl-web/ui-components";
import {
  cx,
  useAppVariant,
  useAutoResizedTextarea,
  useTranslations,
} from "@jugl-web/utils";
import { ReactComponent as StarIcon } from "./assets/star.svg";

export type FeedbackHashtag = {
  id: string;
  label: string;
};

export const FeedbackForm: React.FC<{
  onSubmit: (
    feedback: string,
    starsCount: number,
    hashtags: FeedbackHashtag[]
  ) => void;
  isLoading: boolean;
}> = ({ onSubmit, isLoading }) => {
  const [starsCount, setStarsCount] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [hashtags, setHashtags] = useState<FeedbackHashtag[]>([]);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps } = useAutoResizedTextarea(textareaRef);
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const feedbackHashtags: FeedbackHashtag[] = useMemo(
    () => [
      {
        id: "good-communication",
        label: t({
          id: "guest-task-page.good-communication",
          defaultMessage: "Good communication",
        }),
      },
      {
        id: "professionalism",
        label: t({
          id: "guest-task-page.professionalism",
          defaultMessage: "Professionalism",
        }),
      },
      {
        id: "completed-on-time",
        label: t({
          id: "guest-task-page.completed-on-time",
          defaultMessage: "Completed on time",
        }),
      },
      {
        id: "good-estimation",
        label: t({
          id: "guest-task-page.good-estimation",
          defaultMessage: "Good estimation",
        }),
      },
    ],
    [t]
  );

  return (
    <div className="flex flex-col gap-7">
      <div className={cx("flex gap-4", isMobile && "justify-center")}>
        {Array.from({ length: 5 }, (_, idx) => (
          <PlainButton
            key={+idx}
            className={
              idx < starsCount ? "text-gradients-warning" : "text-grey-400"
            }
            onClick={() => setStarsCount(idx + 1)}
          >
            <StarIcon />
          </PlainButton>
        ))}
      </div>
      <textarea
        ref={textareaRef}
        placeholder={t({
          id: "guest-task-page.share-your-feedback",
          defaultMessage: "Please share your feedback",
        })}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        className="bg-grey-200 placeholder:text-grey-800 text-dark resize-none rounded-2xl border-none p-4 text-sm leading-[21px] outline-none"
        rows={3}
        {...textareaProps}
      />
      <div className="flex flex-wrap gap-2">
        {feedbackHashtags.map((hashtag) => {
          const isChecked = hashtags.some((el) => el.id === hashtag.id);

          return (
            <InteractiveContainer
              className={cx(
                "font-secondary rounded-2xl py-2.5 px-4 text-sm transition-colors",
                isChecked
                  ? "bg-primary-300 font-semibold text-white"
                  : "bg-primary-50 text-black"
              )}
              key={hashtag.id}
              onClick={() =>
                setHashtags((prev) => {
                  if (isChecked) {
                    return prev.filter((el) => el.id !== hashtag.id);
                  }
                  return [...prev, hashtag];
                })
              }
            >
              {hashtag.label}
            </InteractiveContainer>
          );
        })}
      </div>
      <Button
        className={cx("mt-3 ", isMobile ? "mx-auto w-[80%]" : "w-[200px]")}
        isDisabled={!feedback.length || isLoading}
        onClick={() => onSubmit(feedback, starsCount, hashtags)}
      >
        {isLoading ? (
          <LoadingSpinner size="sm" />
        ) : (
          t({
            id: "guest-task-page.send-feedback",
            defaultMessage: "Send feedback",
          })
        )}
      </Button>
    </div>
  );
};
