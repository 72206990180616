import React, { PropsWithChildren } from "react";

export const PageSection: React.FC<
  PropsWithChildren<{
    icon: React.ReactNode;
    title: string;
  }>
> = ({ icon, title, children }) => (
  <div className="px-4">
    <div className="flex items-center gap-2">
      {icon}
      <span className="text-dark font-secondary text-sm leading-3 tracking-[0.14px]">
        {title}
      </span>
    </div>
    <div className="mt-7">{children}</div>
  </div>
);
