import { AUTH_TOKEN_KEY } from "./consts";

const localStorageKey = `${AUTH_TOKEN_KEY}:${
  // eslint-disable-next-line no-restricted-globals
  location.pathname.match(/\/guest\/task\/([A-Za-z0-9-]+)*/)?.[1]
}`;

class TokenStorage {
  private static instance: TokenStorage;

  private token: string | null;

  private constructor() {
    this.token = null;
  }

  public static getInstance(): TokenStorage {
    if (!TokenStorage.instance) {
      TokenStorage.instance = new TokenStorage();
    }
    return TokenStorage.instance;
  }

  public getToken(): string | null {
    this.token = localStorage.getItem(localStorageKey);
    return this.token;
  }

  public setToken(token: string): void {
    localStorage.setItem(localStorageKey, token);
    this.token = token;
  }

  public clearToken(): void {
    localStorage.removeItem(localStorageKey);
    this.token = null;
  }
}

export const tokenStorage = TokenStorage.getInstance();
